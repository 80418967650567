<template>
  <v-card class="mx-auto" max-width="340" outlined elevation="3">
    <v-card-text>
      <div class="text-center d-flex flex-column flex-center">
        <qr-code
          :size="280"
          :text="$store.state.valueQr"
          color="#000"
          bg-color="#fff"
          error-level="L" />

        <span class="mt-3">{{ $store.state.valueQr }}</span>
      </div>
    </v-card-text>
		<!-- <v-card-actions>
			<v-spacer />
			<v-btn small dark class="primary" @click="goViewLabels">ETIQUETAS <v-icon class="ml-2">mdi-chevron-double-right</v-icon></v-btn>
		</v-card-actions> -->
  </v-card>
</template>

<script>
  export default {
    name: 'showQrComponent',
		methods: {
			goViewLabels(){
				this.$router.push({name:'AdminLabels'})
			}
		}

  }
</script>
